<template>
  <div id="app">
    <DefaultLayout/>
  </div>
</template>

<script>
import DefaultLayout from '@/views/layouts/DefaultLayout'

export default {
  components: {
    DefaultLayout,
  },
}
</script>

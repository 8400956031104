<template>
  <div id="header" ref="mainHeader" :class="[isIos ? 'header-ios' : '']">
    <div id="top-wrapper">
      <div class="desktop-header d-none d-lg-flex">
        <a @click="handleHomeClick">
          <img
            class="logo"
            alt="National Garden Scheme logo"
            src="@/assets/images/logo.png">
        </a>
        <Search class="ml-2"/>
        <div class="back-to-ngs-link-wrapper">
          <b-button
            class="back-to-ngs-link"
            href="https://ngs.org.uk"
            variant="link"
          >
            <h4>Back to homepage</h4>
          </b-button>
        </div>
      </div>
      <div class="mobile-header d-lg-none d-flex">
        <a @click="handleHomeClick">
          <img
            class="logo"
            alt="National Garden Scheme logo"
            src="@/assets/images/logo.png">
        </a>
        <div class="flex-grow-1">
          <Search />
          <button @click="toggleUseCurrentLocation" class="mobile-header__location">
            {{ currentLocationText }}
          </button>
        </div>
        <div class="filter-sidebar">
          <FilterSidebar icon-only :disable-filters="disableFilters"/>
        </div>
      </div>
    </div>
    <FilterButtons/>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { mapActions, mapGetters } from 'vuex'
import FilterButtons from '@/components/header/filters/FilterButtons'
import Search from '@/components/header/search/Search'
import FilterSidebar from '@/components/header/filters/FilterSidebar'

export default {
  name: 'FilterHeader',

  components: {
    FilterButtons,
    Search,
    FilterSidebar,
  },

  created() {
    if (!this.dynamicFilterSections) {
      this.fetchAllFiters()
    }
  },

  computed: {
    ...mapGetters('location', [
      'useCurrentLocation',
    ]),
    ...mapGetters('filter', [
      'dynamicFilterSections',
    ]),
    disableFilters() {
      return this.$route.name === 'Garden'
    },
    isIos() {
      return Capacitor.getPlatform() === 'ios';
    },
    currentLocationText() {
      return this.useCurrentLocation ? 'Turn off current location' : 'Use my current location'
    },
  },

  methods: {
    ...mapActions('filter', [
      'fetchAllFiters',
    ]),
    ...mapActions('location', [
      'setUseCurrentLocation',
    ]),
    toggleUseCurrentLocation() {
      this.setUseCurrentLocation(!this.useCurrentLocation)
    },
    handleHomeClick() {
      if (this.useCurrentLocation) {
        this.setUseCurrentLocation(!this.useCurrentLocation)
      }
      const path = '/'
      if (this.$route.path !== path) this.$router.push(path)
    },
  },
}

</script>

<style lang="scss">
#header {
  #top-wrapper {
    .desktop-header {
      align-items: center;
      padding: 0.5rem;
      display: flex;

      .logo {
        width: 81px;
        cursor: pointer;
      }
      .back-to-ngs-link-wrapper {
        text-align: right;

        .back-to-ngs-link {
          font-size: 0.8125rem;

        }
      }
    }

    .mobile-header {
      display: none;
      padding: 8px 10px;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      position: relative;

      &__location {
        padding: 0;
        background: transparent;
        border: none;
        color: black;
        font-size: 18px;
        font-weight: 600;
      }

      .search-result-button {
        width: 203px;
      }

      .filter-sidebar {
        .filter-sidebar-wrapper {
          .btn {
            border: none;
            background: none;
          }
        }
      }

      .logo {
        width: 47px;
      }
    }
  }
}

.header-ios {
  padding-top: env(safe-area-inset-top);
}
</style>
